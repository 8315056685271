/* header */

.header {
    width: 100%;
    position: relative;
    padding-bottom: 2rem;
}

.header-container {
    width: 80%;
    min-height: 65vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-box {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#window {
    width: 25vw;
    margin-top: 15;
    background-color: #F5EEE6;
    color: rgb(11, 10, 10);
    padding: 15px;
    box-shadow: 0 0 0px black;
    border: 2px solid white;
    border-radius: 10px;

}

.swapbox_select {
    width: 70%;
    float: left;
    z-index: 2;
    
}

.swapbox {
    overflow: auto;
    margin: 0 0;
    padding: 10px;
    background-color: #F5EEE6;
    
}
.account{
    background: linear-gradient(90deg, rgba(2,0,36,0.1699054621848739) 0%, rgba(171,230,232,1) 34%, rgba(0,212,255,0.02984943977591037) 100%);
}
.token_select {
    margin-top: 4vh;
    width: 10%;
    display:flex;
    flex-direction: row;  
   
}


.token_row {
    padding: 5px 10px;
}

.token_img {
    height: 25px;
}

.token_text {
    padding: 3px;
    font-weight: bold;
}

.token_row:hover {
    background-color: #e4e4e4;
    cursor: pointer;
}

.gas_estimate_label {
    padding-bottom: 10px;
}

.modal-body {
    overflow: scroll;
}

@media (max-width: 64em) {
    .header-container {
        flex-direction: column;
        width: 100%;
    }

    #window {
        width: 400px;
        margin-bottom: 35px;
    }
}

@media (max-width: 32em) {
    .header-container {
        flex-direction: column;
        width: 100%;
    }
    
     .header-box {
        width: 100%;
    }

    #window {
        width: 350px;
        margin-bottom: 35px;
    }

    .token_img {
        height: 25px;
        margin-left: 50%;
    }
}
